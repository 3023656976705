import Header from "./Header";
import Sidebar from "./Sidebar";
import GraphComponent from "./GraphComponent";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { JWT } from "jsonwebtoken";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import AlertMessage from "./AlertMessage";
import axios from "axios";
import { error } from "jquery";
import Chart from "react-apexcharts";
import moment, { months } from "moment";
import { useSelector } from "react-redux";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";

import { useNavigate } from "react-router-dom";

var jwt = require("jsonwebtoken");

function DashbordJdc({ hadlerjdc }) {


  const { authType } = useSelector((state) => state.auth);


  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;
  const [useractivedatayear, setuseractivedatayear] = useState([]);
  let ID = localStorage.getItem("ID");
  const [JdcProfession, setJdcProfession] = useState([]);
  const [JdcFilter, setJdcFilter] = useState("");
  const [genderFilter, setJdcGender] = useState("");
  const [data1, setData1] = useState([]);
  const [finduser, setFinduser] = useState([]);
  const [transvalue, settransvalue] = useState([]);
  const [transmonths, settransmonths] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [ShowLoader, setShowLoader] = useState(true);

  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);

  const [loanData, setLoanData] = useState([]);
  const [savingData, setSavingData] = useState([]);

  const [useractivedatamonths, setuseractivedatamonths] = useState([]);

  const [frequency, setFrequency] = useState("month");

  const handlesorting = (event) => {
    let sort = event.target.value;
    setFrequency(sort);
    // UserPermoance(sort);
    console.log(sort);
    // alert(sort)
  };

  const formatNumber = (number) => {
    if (number >= 1e12) {
      return (number / 1e12).toFixed(2) + "T"; 
    } else if (number >= 1e9) {
      return (number / 1e9).toFixed(2) + "B"; 
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(2) + "M"; 
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(2) + "k"; 
    } else {
      return number?.toString(); 
    }
  };
  
  // const Transactionuse= ()=>{
  //   const {orgID} = {orgID: "66d70b29c07299070df590f0"}
  //          axios.get(`/v1/admin/get_org_transections_months/${orgID}`)
  //         .then(resp =>{

  //             let data =resp.data.obj;
  //             settransmonths(Object.keys(data))
  //             settransvalue(Object.values(data))
  //           }).catch(error)

  // }

  useEffect(() => {
    const fetchData = () => {
      let body = {
        Type: frequency,
        category: JdcFilter,
        gender: genderFilter,
        fromDate: fromDate,
        toDate: toDate,
      };
      axios
        .post(`/v1/super-Agent/get_jdc_cust_data_graph`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const userData = res.data.data;
          const loanTransactions = res.data.loanTransactions;
          const savingTransactions = res.data.savingTransactions;

          // Extracting data for bar chart (user activity)
          const userActivityCounts = userData.map((item) => item.count);
          const userActivityMonths = userData.map((item) => item.value);

          // Extracting loan and saving data for line charts
          const loanAmounts = loanTransactions.map((item) => item.totalAmount);
          const savingAmounts = savingTransactions.map(
            (item) => item.totalAmount
          );
          const months = userActivityMonths; // Ensure all datasets have the same categories (months)

          settransmonths(months);
          settransvalue(userActivityCounts);

          setuseractivedatayear(userActivityCounts);
          setuseractivedatamonths(months);
          setLoanData(loanAmounts);
          setSavingData(savingAmounts);
        });
    };

    fetchData();
  }, [frequency, JdcFilter, genderFilter, fromDate, toDate]);

  const chartOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: useractivedatamonths,
      labels: {
        style: {
          colors: "#37697f", // Darker color for better readability
          fontSize: "12px",
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Activity & Savings",
          style: {
            color: "#333",
            fontWeight: "bold",
          },
        },
        labels: {
          style: {
            colors: "#333",
            fontSize: "12px",
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "Loan Amount",
          style: {
            color: "#333",
            fontWeight: "bold",
          },
        },
        labels: {
          style: {
            colors: "#FF4560", // Matching the loan amount line color
            fontSize: "12px",
          },
        },
      },
    ],
    colors: ["#63a8b3", "#FF4560", "#00E396"], // Updated color scheme for better contrast
    series: [
      {
        name: "User Activity (Buildings)",
        type: "bar",
        data: useractivedatayear,
        color: "#63a8b3",
      },
      {
        name: "Loan Amount",
        type: "line",
        data: loanData,
        yAxisIndex: 1, // Assign this data series to the second Y-axis
        stroke: {
          curve: "smooth",
          colors: ["#FF4560"],
        },
      },
      {
        name: "Saving Amount",
        type: "line",
        data: savingData,
        stroke: {
          curve: "smooth",
          colors: ["#00E396"],
        },
      },
    ],
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#333"], // Darker color for data labels
        fontWeight: "bold",
      },
    },
    stroke: {
      width: [0, 3, 3], // Different stroke widths for bars and lines
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      labels: {
        colors: "#333", // Darker text for legend items
      },
    },
    grid: {
      borderColor: "#e7e7e7",
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  const JdcDashboardInfo = () => {
    setShowLoader(true)

    axios
      .post(
        `/v1/super-Agent/get-jdc-dash`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        const findLicenseFees = data.findLicenseFees;
        const finduser = data.finduser;
        const findTrans = data.findTrans;
        setData1(data);

        setFinduser(finduser);
        setShowLoader(false)

        //console.log("@@@",findLicenseFees);
        //console.log(data);
      });
  };

  const JdcCategories = () => {
    axios
      .post(
        `/v1/super-Agent/get-profession-categories `,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let Profession = resp.data?.data;
        console.log({ Profession });
        setJdcProfession(Profession);
      });
  };

  const UserPermoance = () => {
    let body = { Type: frequency, category: JdcFilter, gender: genderFilter };
    axios
      .post(`/v1/super-Agent/get_jdc_cust_data_graph`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.data;
        const year = userData.map((item) => item.count);
        const month = userData.map((item) => item.value);
        console.log("++++++++++++++graph data", year, month);

        setuseractivedatayear(year);
        setuseractivedatamonths(month);
      });
  };

  useEffect(() => {
    // UserPermoance();
  }, [frequency, JdcFilter]);

  const custDetail = (_id) => {
    // window.location = `/org-customer-view/${_id}`;
    navigate(`/crm/${authType}-active-details/${_id}`);
    return false;
  };

  useEffect(() => {
    JdcDashboardInfo();
    UserPermoance();
    hadlerjdc();
    JdcCategories();
    // Transactionuse()
  }, []);





const handleResetDID = ()=>{

  setToDate('')
  setFromDate('')
  setJdcGender('')
  setJdcFilter('')
  setFrequency("month")

}



  return (
    <>
      <ToastContainer position="top-right" />
      <CustomerLoader loader={ShowLoader} />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                {/* {data1.remaning_Licenses >= 15 ? "" : <AlertMessage />} */}
                {/* {!data1.remaning_Licenses >= 15 ? <AlertMessage />:"" } */}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-flush">
                      <div className="bg_div card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px">
                        <h3 className="card-title align-items-start flex-column text-white pt-15">
                          <span className="fw-bold fs-2x mb-3">
                             Dashboard
                          </span>
                          <div className="fs-4 text-white"></div>
                        </h3>
                      </div>

                      <div className="card-body mt-n20">
                        <div className="mt-n20 position-relative dashorgnastion">
                          <div className="row g-md-3 g-1  g-lg-6">
                            <div className="col-md-4 col-sm-6 col-6">
                              <div className="retative bg-gray-100 ative animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fas fa-id-card"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                   
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {formatNumber( data1?.totalIdcount)}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Active IDs{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-6">
                              <div className="retative bg-gray-100  animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                  
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {formatNumber(data1?.JDCfarmers)}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Active Farmers{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-md-4 col-sm-6 col-6">
                              <div className="retative bg-gray-100  animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={data1?.JDCfarmers}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data1.JDCfarmers}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total JDC Farmers
                                  </span>
                                </div>
                              </div>
                            </div> */}

                            {/* <div className="col-md-4 col-sm-6">
                              <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-hands-usd"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={data1?.findNumOfTrans}
                                    className="text-gray-700  fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data1?.findNumOfTrans}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    {" "}
                                  </span>
                                </div>
                              </div>
                            </div> */}

                            {/* <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-landmark"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={formatNumber(data1?.LoanAppCount)}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {formatNumber(data1?.LoanAppCount)}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Loan Applications
                                  </span>
                                </div>
                              </div>
                            </div> */}
                            <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                    
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {formatNumber(data1?.totalTransactions)}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Transactions
                                  </span>
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={formatNumber(data1?.totalLoanRepayment)}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {formatNumber(data1?.totalLoanRepayment)}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                  Total Loan Repayments (ETB)
                                  </span>
                                </div>
                              </div>
                            </div> */}

                            <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                   
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {`${formatNumber(data1?.totalSales)}`}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Store Front Sales (ETB)
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                   
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {`${formatNumber(data1?.totalSaving || 0)}`}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Savings (ETB)
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                   
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {`${formatNumber(data1?.totalLoan || 0)}`}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Loan Amount (ETB)
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                    
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {`${formatNumber(
                                      data1?.noOfCoopertives || 0
                                    )}`}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Numbers Of Cooperative
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                   
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data1?.unionConts}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Numbers Of Unions
                                  </span>
                                </div>
                              </div>
                            </div>

                            {/* {!data1?.remaning_Licenses >= 15 ? (
                              <div className="col-md-4 col-sm-6">
                                <Link to="/remaning-license">
                                  <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5"
                                    style={mystyle}>
                                    <div className="symbol symbol-30px me-5 mb-8">
                                      <span className="symbol-label">
                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                          <i className="fad fa-landmark"></i>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="m-0">
                                      <span
                                        data-kt-countup="true"
                                        data-kt-countup-value={
                                          data1?.remaning_Licenses
                                        }
                                        className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                      >
                                        {data1?.remaning_Licenses}
                                      </span>
                                      <span className="text-gray-500 fw-semibold fs-6">
                                      Remaining D-ID Licences{" "}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              <div className="col-md-4 col-sm-6">
                                <Link to="/remaning-license">
                                  <div
                                    className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5"

                                  >
                                    <div className="symbol symbol-30px me-5 mb-8">
                                      <span className="symbol-label">
                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                          <i className="fad fa-landmark"></i>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="m-0">
                                      <span
                                        data-kt-countup="true"
                                        data-kt-countup-value={
                                          data1?.remaning_Licenses
                                        }
                                        className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                      >
                                        {data1?.remaning_Licenses}
                                      </span>
                                      <span className="text-gray-500 fw-semibold fs-6">
                                      Remaining D-ID Licences{" "}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-lg-12 mt-5">
  <div className="card card-flush h-xl-100 shadow-sm">
    <div className="container card-header pt-5 pb-3" style={{ display: "block" }}>
      <div className="row mb-4 align-items-center">
        <div className="col-md-4">
          <form>
            <div className="d-flex align-items-center gap-2">
              <select
                defaultValue={frequency}
                onChange={(e) => setFrequency(e.target.value)}
                className="form-select graph-select shadow-sm"
              >
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Monthly</option>
                <option value="year">Yearly</option>
              </select>
            </div>
          </form>
        </div>

        <div className="col-md-4">
          <form>
            <div className="d-flex align-items-center gap-2">
              <select
                value={JdcFilter}
                onChange={(e) => setJdcFilter(e.target.value)}
                className="form-select graph-select shadow-sm"
              >
 <option selected>
                    Choose any one option
                  </option>

                {JdcProfession?.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </select>
            </div>

           
          </form>
        </div>
        <div className="col-md-4">
          <form>
          <div className="d-flex align-items-center gap-2">
              <select
                value={genderFilter}
                onChange={(e) => setJdcGender(e.target.value)}
                className="form-select graph-select shadow-sm"
              >
 <option selected>
                    Choose any gender option
                  </option>

                  <option key="Male" value="Male">
                    Male
                  </option>

                  <option key="Female" value="Female">
                    Female
                  </option>
              
              </select>
            </div>

           
          </form>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">Organisation Activity</span>
            <span className="text-muted mt-1 fw-semibold fs-6">Building and Graph Activity Overview</span>
          </h3>
        </div>
      </div>
    </div>

    <div className="bg-light p-4 rounded shadow-sm">
      <Chart
        options={chartOptions}
        series={chartOptions.series}
        type="line"
        width="100%"
        height={400}
      />
    </div>
  </div>
</div> */}

                 

                  <div className="col-lg-12 mt-5">
                    <div className="card card-flush h-xl-100">
                      <div className="card-header pt-7">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold text-gray-800">
                            User Onboarded Activity
                          </span>
                          <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
                        </h3>
                        <div className="row mb-4 align-items-center">
                          {/* Frequency Filter */}
                          <div className="col-md-4">
                            <form>
                              <div className="d-flex align-items-center gap-2">
                                <select
                                  value={frequency}
                                  onChange={(e) => setFrequency(e.target.value)}
                                  className="form-select graph-select shadow-sm"
                                >
                                  <option value="day">Day</option>
                                  <option value="week">Week</option>
                                  <option value="month">Monthly</option>
                                  <option value="year">Yearly</option>
                                </select>
                              </div>
                            </form>
                          </div>

                          {/* Month Filter */}
                          <div className="col-md-4">
                            <form>
                              <div className="d-flex align-items-center gap-2">
                                <select
                                  value={JdcFilter}
                                  onChange={(e) => setJdcFilter(e.target.value)}
                                  className="form-select graph-select shadow-sm"
                                >
                                  <option selected>
                                    Choose any one option
                                  </option>

                                  {JdcProfession?.map((item) => (
                                    <option key={item.value} value={item.value}>
                                      {item.value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </form>
                          </div>

                          <div className="col-md-4">
                            <form>
                              <div className="d-flex align-items-center gap-2">
                                <select
                                  value={genderFilter}
                                  onChange={(e) => setJdcGender(e.target.value)}
                                  className="form-select graph-select shadow-sm"
                                >
                                  <option selected>
                                    Choose any gender option
                                  </option>

                                  <option key="Male" value="Male">
                                    Male
                                  </option>

                                  <option key="Female" value="Female">
                                    Female
                                  </option>
                                </select>
                              </div>
                            </form>
                          </div>

                          <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
                            <div className="row  justify-content-end  flex-grow-1 mb-5 mb-lg-0">
                              <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                                <label className="small_label my-label">
                                  Start Date
                                </label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                                  <i className="fad fa-calendar"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                                  value={fromDate}
                                  onChange={handleFromDateChange}
                                />
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                              </div>

                              <div className="col-md-3 d-flex ps-1 align-items-center mb-3 input-container mb-lg-0">
                                <label className="small_label my-label">
                                  End Date
                                </label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                                  <i className="fad fa-calendar"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                                  value={toDate}
                                  onChange={handleToDateChange}
                                />
                              </div>
                              <div className="col-md-2 d-flex justify-content-start align-items-center mb-3 ps-1 input-container mb-lg-0">
                                <button
                                        type="reset"
                                        onClick={()=>handleResetDID()}
                                        className="btn btn-light btn-active-light-primary"
                                        id="kt_advanced_search_button_1"
                                    >
                                       Reset
                                    </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-white overflow-auto">
                        <Chart
                          type="area"
                          width={"100%"}
                          height={400}
                          series={[
                            {
                              name: "User",
                              data: transvalue,
                            },
                          ]}
                          options={{
                            plotOptions: {
                              bar: {
                                borderRadius: 2,
                                columnWidth: 50,
                              },
                            },
                            markers: {
                              size: 0,
                            },
                            stroke: {
                              width: 3,
                              curve: "smooth",
                            },

                            colors: ["#3c7f8c"],
                            theme: { mode: "light" },

                            xaxis: {
                              tickPlacement: "on",
                              categories: transmonths,
                              title: {
                                style: { color: "#f90000", fontSize: 0 },
                              },
                            },

                            yaxis: {
                              labels: {
                                formatter: (val) => {
                                  return `${val}`;
                                },
                              },
                              //
                            },

                            legend: {
                              show: true,
                              position: "right",
                            },

                            dataLabels: {
                              formatter: (val) => {
                                return `${val}`;
                              },
                              style: {
                                colors: ["#3c7f8c"],
                                fontSize: 15,
                              },
                            },
                          }}
                        ></Chart>
                      </div>
                    </div>
                  </div>

                  <GraphComponent />
                </div>
              </div>

              {/* <div className="col-lg-12 mt-4">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Recent Users
                      </span>
                      
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">Name</th>
                            <th className="min-w-150px">D-ID ref No.</th>
                            <th className="min-w-150px">Contact No. </th>
                            <th className="min-w-100px">Date of creation</th>
                            <th className="min-w-100px ">Status</th>
                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {finduser?.map((item) => (
                            <tr>
                              <td>{item.fullname}</td>
                              <td>{item.digitalrefID}</td>
                              <td>
                                <span>{item.phone}</span>
                              </td>
                              <td>
                                {moment(item.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                {item.status == "pending" ||
                                  item.status == "inactive" ? (
                                  <>
                                    <td>
                                      <span className="text-danger">
                                        {item.status?.charAt(0).toUpperCase() +
                                          item.status?.slice(1)}
                                      </span>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <span className="text-success">
                                          {item.status?.charAt(0).toUpperCase() +
                                            item.status?.slice(1)}
                                        </span>
                                    </td>
                                  </>
                                )}
                              </td>
                              <td>
                               <div className="d-flex justify-content-center flex-shrink-0">
                                  <button
                                    onClick={(e) => custDetail(item._id)}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashbordJdc;
